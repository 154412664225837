@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
